#unfunded_accounts {
  .unfunded_content {
    .unfunded_tab {
      text-align: center;
      margin-bottom: 40px;
    }

    .table_content {
      width: 100%;

      > li {
        width: 100%;
        @include rtl-sass-value(float, left, right);
        display: none;
      }

      > li.active {
        display: block;
      }

      .table_box {
        margin-bottom: 30px;

        /deep/ .el-table {
          .cell {
            word-break: break-word;
            padding: 0;
          }

          th {
            line-height: 51px;
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  #unfunded_accounts {
    .unfunded_content li {
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
